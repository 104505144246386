import React from "react";
import { Nav, NavLink, NavIcon, Bars } from "./NavbarElements";
//import ImgBg from '../../images/block_coffee_logo.png';

const Navbar = ({ toggle }) => {
  const params = new URLSearchParams(window.location.search);
  const param1 = params.get('isApp');
  if(param1) return <></>;
  return (
    <Nav>
      <NavLink
        to="/"
        style={{
          fontFamily: "Montserrat-ExtraBold",
        }}
      >
        {/* <img className="logo-back" src={ImgBg}/> */}
        Block.
      </NavLink>
      <NavIcon onClick={toggle}>
        {/* <p
          style={{
            fontFamily: "Montserrat-ExtraBold",
            fontSize: 22,
            position: "absolute",
            right: "auto",
          }}
        >
          Menu
        </p> */}
        <Bars />
      </NavIcon>
    </Nav>
  );
};

export default Navbar;
