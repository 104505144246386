import product1 from "../../images/block-3.png";
import product2 from "../../images/block-2.jpg";
import product3 from "../../images/block-3.jpg";
// import product1 from "../../images/DSC_0429.JPG";
// import product2 from "../../images/DSC_0446.JPG";
// import product3 from "../../images/DSC_0537.JPG";
// import sweet1 from "../../images/sweet3.jpg";
// import sweet2 from "../../images/sweet-2.jpg";
// import sweet3 from "../../images/sweet-3.jpg";

export const productsData = [
  {
    img: product1,
    alt: "Coffee",
    //name: "FRAPPUCCINO",
  },
  {
    img: product2,
    alt: "Coffee",
    //name: "CARAMEL MACCHIATO",
  },
  {
    img: product3,
    alt: "Coffee",
    //name: "AMERICANO",
  },
];
