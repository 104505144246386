import React from "react";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink,
  FooterText,
  FooterMedia,
} from "./FooterElements";

const Footer = () => {
  const params = new URLSearchParams(window.location.search);
  const param1 = params.get("isApp");
  if (param1) return <></>;
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" style={{ fontFamily: "Montserrat-ExtraBold" }}>
              Block.
            </SocialLogo>
            <SocialIcons>
              {/* <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconLink> */}
              <SocialIconLink
                href="https://www.instagram.com/block.coffeeroastery/"
                target="_blank"
                aria-label="Instagram"
              >
                <FaInstagram />
              </SocialIconLink>
              {/* <SocialIconLink href="/" target="_blank" aria-label="Youtube">
                <FaYoutube />
              </SocialIconLink> */}
              {/* <SocialIconLink
                href="https://twitter.com/kart_witter"
                target="_blank"
                aria-label="Twitter"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink href="/" target="_blank" aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconLink> */}
            </SocialIcons>
          </SocialMediaWrap>
          <FooterMedia>
            <FooterText href="/franchise">FRANCHISE</FooterText>
            <FooterText href="/kullanici-sozlesmesi">
              KULLANICI SÖZLEŞMESİ
            </FooterText>
            <FooterText href="/gizlilik-politikasi">
              GİZLİLİK POLİTİKASI
            </FooterText>
          </FooterMedia>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
