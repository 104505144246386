import React, { useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn,
} from "./HeroElements";

const Hero = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeroContainer>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HeroContent>
        {/* <HeroItems>
          <HeroH1>Lısten Nobody Block Them.</HeroH1>
          <HeroP>
            Your style your Coffee at{" "}
            <a style={{ fontFamily: "Montserrat-ExtraBold" }}>Block</a>.
          </HeroP>

        </HeroItems> */}
        {/* <a
          style={{
            display: "block",
            marginBottom: "20px",
            padding: "0 20px",
            fontFamily: "Montserrat-ExtraBold",
            position: "absolute",
            bottom: 0,
            color: "white",
            fontSize: "13px",
          }}
        >
          Block Coffee 2023 yılında, minimalist bir dekorasyon anlayışıyla
          Tekirdağ'da kuruldu. Yılların getirmiş olduğu engin tecrübe ve bilgi
          birikimini, kahve çekirdekleriyle harmanlayarak, eşsiz tadını kahve
          severlerle buluşturdu. Kahvelerini kavururken hasat dönemini, dünya
          trendlerini ve misafir geri bildirimlerini göz önünde bulundururken en
          taze, en doğal ve nitelikli kahve çekirdeklerini kullanmaya özen
          göstermektedir.
        </a> */}
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
