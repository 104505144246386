import React from "react";

import { GlobalStyle } from "../globalStyles";

import Footer from "../components/Footer";
import Bar from "../components/Bar";

const Kullanici = () => {
  return (
    <div>
      <GlobalStyle />
      <Bar text={"KULLANICI SÖZLEŞMESİ"} newFont={"clamp(1rem,10vw,2rem)"} />
      <div
        style={{
          background: "#0d0909",
          padding: "20px 30px 30px 30px",
          margin: "auto",
          maxWidth: "1300px",
        }}
      >
        <text style={{ color: "white" }}>
          <h2>MADDE 1 – TARAFLAR</h2> İşbu Kullanıcı Sözleşmesi (a)
          [Değirmenaltı Mah. İğde Sok. No:6/A Süleymanpaşa/Tekirdağ] adresinde
          mukim [BLOCK COFFEE (UMUT ÜSTÜN)] (bundan böyle “Block Coffee” olarak
          anılacaktır) ile (b) Şirket’in internet sitesi ve mobil
          uygulamalarından işbu sözleşmede belirtilen koşullar dahilinde
          yararlanan Kullanıcı ile karşılıklı olarak kabul edilerek yürürlüğe
          girmiştir (“Sözleşme”).
          <br />
          <br /> <h2>MADDE 2 – TANIMLAR</h2> Sözleşme’nin diğer maddelerinde
          tanımlananlara ek olarak aşağıda tanımlı ifadeler kendilerine bu
          Madde’de atfedilen anlamı ifade ederler: <br />
          <br /> 2.1. “İletişim Kanalları” anında bildirim, e-posta, sms, mms,
          telefon ile bildirim gibi iletişim mecralarını ifade eder.
          <br />
          <br /> 2.2. “İnternet sitesi” [www.blockcoffee.com.tr] adlı internet
          sitesini ifade etmektedir. <br />
          <br />
          2.3. “Kullanıcı” Uygulama’dan ve Şirket tarafından sunulan
          hizmetlerden işbu sözleşmede belirtilen koşullar dahilinde yararlanan
          kişileri ifade etmektedir.
          <br />
          <br /> 2.4. “Mesaj”: Şirket’in, Kullanıcılar’a pazarlama, araştırma,
          bilgilendirme vb. amaçlarla göndereceği, Kullanıcılar’ın Şirket
          tarafından kendilerine gönderilmesine izin verdikleri mesajları ifade
          eder. <br />
          <br />
          2.5. “Uygulama” İnternet sitesinden sunulan hizmetleri veya mobil
          uygulamaların aplikasyon indirme programları üzerinden indirilen
          [Block Coffee] uygulamayı ifade etmektedir.
          <br />
          <br /> 2.6. “Taraf”: Şirket ile Kullanıcı’dan her birini ifade eder.
          Kullanıcı ve Şirket, birlikte “Taraflar” olarak anılacaklardır. <br />
          <br />
          <h2>MADDE 3 – KONU VE KAPSAM</h2>
          3.1. İşbu sözleşmenin konusu; Kullanıcı’ya yönelik olarak Uygulama’da
          mevcut olan ve ileride Şirket tarafından sunulacak tüm ürün ve
          hizmetlerin, bu ürün ve hizmetlerden yararlanma şartlarının ve
          tarafların hak ve yükümlülüklerinin tespitidir. <br />
          <br />
          3.2. Kullanıcı, işbu sözleşme hükümlerini kabul etmekle, Uygulama
          içinde yer alan kullanıma, üyeliğe ve hizmetlere ilişkin Şirket
          tarafından açıklanan her türlü beyanı da kabul etmiş olmaktadır.
          Kullanıcı, bahsi geçen beyanlarda belirtilen her türlü hususa uygun
          olarak davranacağını kabul, beyan ve taahhüt eder. <br />
          <br />
          <h2>MADDE 4 – UYGULAMA KULLANIM ŞARTLARI</h2>
          4.1. Reşit olmayan veya tüzel kişi üyeler için tüzel kişiyi temsil ve
          ilzam etmeye yetkili olmayan kişiler kanalıyla yapılan başvurular,
          kayıt işlemlerini tamamlamış olsa dahi “Kullanıcı”ya bu Sözleşme’de
          tanınan haklardan yararlanamazlar. Aksi yöndeki talep ve işlemler
          Şirket’in sorumluğunda değildir. <br />
          <br />
          4.2. Şirket, her zaman herhangi bir gerekçe göstermeden, herhangi bir
          bildirimde bulunmadan ve herhangi bir tazminat ödeme yükümlülüğü
          bulunmadan ve derhal yürürlüğe girecek şekilde işbu Sözleşme’yi tek
          taraflı olarak feshedebilir veya sair şekilde Kullanıcı’nın
          Uygulama’dan veya Şirket’in ürün ve hizmetlerinden yararlanmasına son
          verebilir. <br />
          <br />
          <h2>MADDE 5– KARŞILIKLI HAK VE YÜKÜMLÜLÜKLER</h2> <br />
          <br />
          <h3>5.1. Kullanıcı’nın Hak ve Yükümlülükleri:</h3> <br />
          a) Kullanıcı, Uygulama’daki ürün veya hizmetlerden faydalanırken ve
          Uygulamadaki ürün veya hizmetler ile ilgili herhangi bir işlemi yerine
          getirirken işbu Sözleşme içerisinde yer alan tüm şartlara, İnternet
          Sitesi’nde ve Uygulama’da belirtilen kurallara ve yürürlükteki tüm
          mevzuata uygun hareket edeceğini, işbu Sözleşme’de belirtilen tüm şart
          ve kuralları okuduğunu, anladığını ve onayladığını kabul eder.
          <br />
          <br /> b) Kullanıcı, Şirket’in yasal mevzuat gereği resmi makamlara
          açıklama yapmakla yükümlü olduğu durumlarda, resmi makamlarca usulü
          dairesinde bu bilgilerin talep edilmesi halinde, Kullanıcılar’a ait,
          Uygulama dahilinde edinilen bilgileri resmi makamlara açıklamaya
          yetkili olacağını ve bu sebeple kendisinden her ne nam altında olursa
          olsun tazminat talep edilemeyeceğini kabul eder. <br />
          <br />
          c) Kullanıcı, Şirket’in iş bu Sözleşme konusu ürün ve hizmetleri
          ifasına yönelik olarak ve/veya bu amaçla anlaşma akdettiği iş
          ortaklarına, Kullanıcı’ya ait ticari bilgileri açıklamaya yetkili
          olacağını, bu hususu kabul ettiğini beyan ederek bu sebeple Şirket’ten
          her ne nam altında olursa olsun tazminat talep edilemeyeceğini kabul
          eder. <br />
          <br />
          d) Kullanıcı, Uygulama dahilinde kendisi tarafından sağlanan
          bilgilerin ve içeriklerin doğru ve hukuka uygun olduğunu kabul ve
          taahhüt eder. Şirket, Kullanıcı tarafından Şirket’e iletilen veya
          Uygulama üzerinden kendisi tarafından yüklenen, değiştirilen ve
          sağlanan bilgilerin ve içeriklerin doğruluğunu araştırma, bu bilgi ve
          içeriklerin güvenli, doğru ve hukuka uygun olduğunu taahhüt ve garanti
          etmekle yükümlü ve sorumlu olmadığı gibi, söz konusu bilgi ve
          içeriklerin yanlış veya hatalı olmasından dolayı ortaya çıkacak hiçbir
          zarardan da sorumlu değildir. <br />
          <br />
          e) Kullanıcı, Şirket’e verdiği İletişim Kanalları’nın yalnızca
          kendisine ait ve yalnızca kendi kontrolü altındaki İletişim Kanalları
          olduğunu ve bu iletişim kanallarını aktif olarak kullandığını taahhüt
          eder. Aksi halde, Kullanıcı iletişim kanallarının üyenin kendisine ait
          olmamasından dolayı Şirket’in uğrayabileceği her türlü maddi ve manevi
          zararı tazminle yükümlü olacaktır.
          <br />
          <br /> f) Şirket’in sunduğu ürün ve hizmetlerden yararlanan Kullanıcı,
          Uygulama dahilinde yalnızca hukuka uygun amaçlarla işlem yapabilirler.
          Kullanıcı’nın, Uygulama dahilinde yaptığı her işlem ve eylemdeki
          hukuki ve cezai sorumluluk kendisine aittir. Kullanıcı, Uygulama’yı
          kullanırken Türk Ceza Kanunu, Türk Ticaret Kanunu, Borçlar Kanunu,
          5651 sayılı İnternet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu
          Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun,
          Fikir ve Sanat Eserleri Kanunu, Marka, Patent, Faydalı Model,
          Endüstriyel Tasarım Haklarının Korunması ile ilgili kanun hükmünde
          kararnameler ve yasal düzenlemeler, halihazırda yürürlükte olan ilgili
          her türlü mevzuat hükümleri ile Şirket’in Uygulamaya ilişkin olarak
          yayımlayacağı bildirimlere uymayı kabul eder. <br />
          <br />
          g) Kullanıcı işbu Uygulama’nın Şirket’e ait olduğunu ve/veya onun
          tarafından işletildiğini kabul eder. İşbu uygulamanın içeriği Fikri ve
          Sınai haklar doğrultusunda korunmaktadır. Kullanıcı, Şirket ve/veya
          başka bir üçüncü şahsın aynı veya şahsi haklarına, malvarlığına
          tecavüz teşkil edecek nitelikteki Uygulama dahilinde bulunan
          resimleri, metinleri, görsel ve işitsel imgeleri, video klipleri,
          dosyaları, veri tabanlarını, katalogları ve listeleri
          çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini
          kabul ve taahhüt etmektedir. <br />
          <br />
          h) Uygulama’da ve Uygulama üzerinden link verilen internet
          sitelerinde, Kullanıcı de dahil olmak üzere üçüncü kişiler tarafından
          yayınlanan içeriklerden dolayı Şirket’in herhangi bir sorumluluğu
          bulunmamaktadır. Herhangi bir üçüncü kişi tarafından sağlanan ve
          yayınlanan bilgilerin, içeriklerin, görsel ve işitsel imgelerin
          doğruluğu ve hukuka uygunluğunun taahhüdü bütünüyle bu eylemleri
          gerçekleştiren kişilerin sorumluluğundadır. Şirket, üçüncü kişiler
          tarafından sağlanan hizmetlerin ve içeriklerin güvenliğini,
          doğruluğunu ve hukuka uygunluğunu taahhüt ve garanti etmemektedir.{" "}
          <br />
          <br />
          i) Kullanıcı ve Şirket birbirinden bağımsız taraflardır. İşbu
          Sözleşme’nin Taraflar’ca onaylanması ve uygulanması, aralarında
          ortaklık, temsilcilik veya işçi–işveren ilişkisi olduğu sonucunu
          doğurmaz. <br />
          <br />
          j) Kullanıcı işbu Sözleşme kapsamında üçüncü kişilere göndereceği
          referans mesajlarının hukuka ve ahlaka aykırı unsurlar içermeyeceğini
          kabul, beyan ve taahhüt eder. Kullanıcı, göndereceği elektronik
          postalar ile üçüncü kişilerce istenmeyen elektronik posta iletişimi
          olarak nitelendirilebilecek yoğunlukta bir iletişimde bulunmayacağını
          kabul, beyan ve taahhüt eder. Kullanıcı, bu madde hükmüne aykırı
          olarak yapacağı bütün iletişimin sorumluluğunun kendisine ait
          olduğunu, her ne sebeple olursa olsun bu iletişimlerden kaynaklı
          olarak Şirket’e bir dava ve talep yöneltilmesi halinde; bu dava ve
          talebin Şirket tarafından kendisine ihbar edilebileceğini, Şirket’in
          savunma yapması için gerekli olan her türlü bilgi ve içeriği kendisine
          savunma için gerekli yasal sürelerin geçirilmemesini teminen derhal
          sağlayacağını, bahsi geçen dava ve talep sonucunda Şirket’in herhangi
          bir zarara uğraması durumunda bahsi geçen zararın Şirket tarafından,
          tazminat hakları saklı kalmak kaydıyla, kendisine rücu
          ettirilebileceğini kabul, beyan ve taahhüt eder. Kullanıcının iş bu
          maddeye aykırı hareket etmesi sebebiyle Şİrket işbu Sözleşmeyi
          herhangi bir bildirimde bulunmadan tek taraflı olarak feshetme,
          üyeliği askıya alma veya iptal etme hakkını saklı tutmaktadır.
          <br />
          <br /> <h3>5.2. Şirket’in Hak ve Yükümlülükleri:</h3>
          <br />
          a) Şirket, Uygulama’da sunulan ürün ve hizmetleri ve içerikleri her
          zaman tek taraflı olarak değiştirebilme, Kullanıcı’nın sisteme
          yüklediği bilgileri ve içerikleri Kullanıcı da dahil olmak üzere
          üçüncü kişilerin erişimine kapatabilme ve silme hakkını saklı
          tutmaktadır. Şirket, bu hakkını hiçbir bildirimde bulunmadan ve önel
          vermeden kullanabilir. Şirket tarafından Kullanıcıdan talep edilen
          değişiklik ve/veya düzeltme istekleri belirtilen süre içerisinde
          yerine getirilmediği takdirde doğmuş ve doğabilecek zararlardan Şirket
          sorumlu olmayacak ve Kullanıcının Uygulamayı kullanma hakkını
          dondurabilme veya sonlandırma hakkına sahip olacaktır.
          <br />
          <br /> b) Referans kolaylığı veya çeşitli sebepler nedeniyle Uygulama
          içerisinde Şirket kontrolünde olmayan bir takım web sitelerine veya
          içeriklere linkler verilebilir. Bu linkler bahsi geçen web sitesini
          veya içerik sahibini destekleyici mahiyette olmadığı gibi, web sitesi
          veya içerikte yer alan bilgilere yönelik herhangi bir türde bir beyan
          veya garanti niteliği de taşımamaktadır. Şirket, üçüncü kişilerin web
          sitelerinin muhtevasının burada sayılanlarla sınırlı olmamakla
          birlikte; yasal, doğru, güvenilir, yerinde ve uygun olduğu, içeriğinde
          kullanılan bilgi, veri, yazılım, ürün veya hizmetlerin ticarete
          elverişli nitelikte olduğu, web sitesinin işletme ve idaresinin
          hatasız ve kesintisiz olacağı, aksaklıklar, kusur ve bozuklukların
          düzeltileceği, İnternet Sitesi’nin zarar verici unsurlardan ve
          virüslerden temizlenmiş olduğu hususlarında herhangi bir öneri ve
          tavsiyede bulunmamakta ve bu hususlarda kullanıcıya herhangi bir
          güven, beyan ve teminat vermemektedir. Uygulama üzerindeki linkler
          vasıtasıyla erişilen portallar, web siteleri, dosyalar ve içerikler,
          bu linkler vasıtasıyla erişilen portallar veya web sitelerinden
          sunulan hizmetler veya ürünler veya bunların içeriği hakkında
          Şirket’in herhangi bir sorumluluğu yoktur. <br />
          <br />
          c) Şirket, Uygulama’da yer alan Kullanıcı bilgilerini Sözleşme
          kapsamında sunduğu ürün ve hizmetler dışında da Kullanıcı güvenliği,
          kendi yükümlülüğünü ifa ve bazı istatistiki değerlendirmeler ve iç
          denetimler için veya demografik bilgi olarak reklam ve/veya tanıtım
          için dilediği biçimde kullanabilir, bunları bir veri tabanı üzerinde
          tasnif edip muhafaza edebilir. <br />
          <br />
          d) Şirket, işbu Uygulama ve uzantısında mevcut her tür hizmet, ürün,
          Uygulama’yı kullanma koşulları ile Uygulama’da sunulan bilgileri
          önceden bir ihtara gerek olmaksızın değiştirme, yeniden organize etme,
          Uygulama’yı durdurma hakkını saklı tutar. Değişiklikler, Uygulama’da
          yayım anında yürürlüğe girer. Kullanıcılar, Uygulama’nın kullanımı ya
          da Uygulama’ya giriş ile bu değişiklikleri de kabul etmiş sayılır.
          Değişiklikler, Şirket tarafından belirlenen mecra üzerinden
          Kullanıcılar’a duyurulur. <br />
          <br />
          e) Şirket, Uygulama kapsamında verdiği hizmetlerin sürekli aktif ve
          erişilebilir olacağına dair hiçbir garanti vermemektedir. Şirket
          özellikle yargı ve sair yetkili resmi merci karar ve uygulamaları,
          mücbir sebepler, üçüncü kişilerin sebep olduğu durumlar, Internet
          bağlantı hizmeti sağlayan kuruluşlardan kaynaklanan aksaklıklar ve
          gecikmeler ve benzeri dış etkenler, yanlış kullanım, Şirket’ten
          kaynaklanabilecek teknik arıza ve sair aksaklıklar, tamir çalışmaları
          veya diğer yönlendirmeler sonucu meydana gelen aksaklıklar konusunda
          hiçbir şekilde sorumluluk kabul etmez.
          <br />
          <br /> f) Şirket, gerektiğinde, ağın işletim güvenliğinin tehlikede
          olması, ağa erişimin devamlılığı, ağda, yazılımda veya kayıtlı
          dosyalarda meydana gelebilecek arızaların önüne geçebilmek, muhtemel
          aksaklıkları engellemek veya etkisini azaltmak adına ve gerekli
          gördüğü diğer durumlarda hizmetlerine, Uygulama’ya, İnternet Sitesi’ne
          ve sair ürünlerine erişimi sınırlandırabilir veya durdurabilir. <br />
          <br />
          <h2>MADDE 6 – GİZLİLİK</h2> Şirket, Kullanıcılar’la ilgili bilgileri
          işbu Sözleşme’nin kapsamı dışında işbu Sözleşme’nin EK-1 bölümünde yer
          alan ve Sözleşme’nin ayrılmaz bir parçası olan “Gizlilik Politikası”
          kapsamında kullanabilir. Sodexo, Kullanıcılar’a ait gizli bilgileri,
          işbu Sözleşme’nin kapsamı dışında ancak “Gizlilik Sözleşmesi”nde
          belirtilen koşullar dahilinde üçüncü kişilere açıklayabilir veya
          kullanabilir.
          <br />
          <br /> İnternet Sitesi veya Uygulama’nın virüs ve benzeri amaçlı
          yazılımlardan arındırılmış olması için mevcut imkânlar dâhilinde
          tedbir alınmış olmakla birlikte, nihai güvenliğin sağlanması için
          Kullanıcı, kendi virüs koruma sistemini tedarik etmek ve gerekli
          korumayı sağlamakla yükümlüdür. Bu çerçevede kullanıcı, İnternet
          Sitesi’ne girmesi veya Uygulama’yı kullanması nedeniyle, kendi yazılım
          ve işletim sistemlerinde oluşabilecek tüm hata ve bunların doğrudan ya
          da dolaylı sonuçlarından kendisinin sorumlu olduğunu kabul eder.
          <br />
          <br />
          Şirket, kendi takdirinde olmak üzere İnternet Sitesi veya Uygulama’nın
          içeriğini dilediği zaman değiştirme, kullanıcılara sağlanan herhangi
          bir hizmeti değiştirme ya da sona erdirme hakkını haizdir. Şirket,
          İnternet Sitesi veya Uygulama’nın hatasız olması için her türlü tedbir
          almış olmakla, sitede mevcut ya da oluşabilecek hatalar ve kesintiler
          ile ilgili herhangi bir garanti vermemektedir.
          <br />
          <br /> İnternet Sitesi veya Uygulama, Şirket’in Kullanıcılar’ı
          tanımlamasına yardımcı olması için Kullanıcılar’ın bilgisayar veya
          mobil cihazlarına bilgi yerleştirilebilir. Bu bilgi, genel olarak
          “cookie” olarak bilinir. Cookie kullanımı, bir sanayi standardıdır ve
          birçok web sitesi bunları kullanmaktadır. Cookie’ler Kullanıcılar’ın
          bilgisayarına veya mobil cihazına kaydedilir. Kullanıcılar Cookie
          ayarlarını kendi mobil cihazları veya web tarayıcıları üzerinden
          değiştirebilir veya yönetebilir; bu konuda Şirket’e herhangi bir
          talepte bulunma hakları veya Şirket’in herhangi bir değişiklik yapma
          yükümlülüğü bulunmamaktadır.
          <br />
          <br /> Şirket istatistik, izleme programı olan Google Analytics’e
          Kullanıcı’nın web tarayıcısı veya mobil cihazı tarafından otomatik
          olarak gönderilen bilgileri toplamaktadır. Bu bilgiler tipik olarak
          Kullanıcı’nın İnternet servis sunucusunun IP adresi, işletim
          sisteminin ismi ve web tarayıcısının versiyonu gibi bilgileri
          içermektedir. Kullanıcılar, tarayıcı ve mobil cihazlarının ne gibi
          bilgiler gönderdiğini ya da ayarlarınızı nasıl değiştireceğini kendi
          bilgisayar, mobil cihaz ve tarayıcı ayarları üzerinden öğrenebilir, bu
          konuda Şirket’ten herhangi bir talep hakkı veya Şirket’in herhangi bir
          yükümlülüğü bulunmamaktadır. Şirket, bu bilgileri ürün ve hizmetlerini
          geliştirmek ve onları müşteriler tarafından kullanılan teknoloji ile
          daha uyumlu hale getirmeye yardımcı olan istatistikler oluşturmak için
          kullanmaktadır.
          <br />
          <br />
          <h2>MADDE 7 – UYGULANACAK HUKUK VE YETKİLİ MAHKEME</h2> İşbu
          Sözleşme’nin uygulanmasında ve yorumlanmasında Türk Hukuku
          uygulanacaktır. İşbu Sözleşme’den dolayı doğan veya doğabilecek her
          türlü ihtilafın hallinde İstanbul Merkez Mahkemeleri ve İcra Daireleri
          yetkilidir. <br />
          <br />
          <h2>MADDE 8 – FİKRİ MÜLKİYET HAKLARI</h2> Uygulama’nın, genel görünüm,
          tasarım, metin, imge, logo, ikon, demonstratif, yazılı, elektronik,
          grafik veya makinede okunabilir şekilde sunulan teknik verileri,
          Şirket’in markası, uygulanan iş metodu ve iş modeli, yazılım kodu ve
          diğer kodlar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla, tüm
          unsurları Şirket’e aittir ve/veya Şirket tarafından üçüncü bir kişiden
          alınan telif hakkı altında kullanılmaktadır. Fikri ve Sınai haklar
          kapsamında korunan Şirket’e ait tüm bu unsurlar önceden izin alınmadan
          ve kaynak gösterilmeden değiştirilemez, kopyalanamaz, çoğaltılamaz,
          başka bir lisana çevrilemez, yeniden yayımlanamaz, yeniden satılamaz,
          paylaşılamaz, dağıtılamaz, sergilenemez, Sözleşme kapsamı dışında
          kullanılamaz veya bunlardan türemiş çalışmalar yapılamaz veya
          hazırlanamaz, ters mühendislik yapılamaz. Aksi şekilde davranılması
          halinde, sorumlu kişi/kişiler Şirket’in uğradığı zararı ve/veya lisans
          verenler de dahil üçüncü kişilerin uğradıkları zararlardan dolayı
          Şirket’ten talep edilen tazminat miktarını, mahkeme masrafları ve
          avukatlık ücreti de dahil olmak üzere karşılamakla yükümlü
          olacaklardır. <br />
          <br />
          <h2>MADDE 9 – SÖZLEŞME DEĞİŞİKLİKLERİ</h2> Şirket, tamamen kendi
          takdirine bağlı ve tek taraflı olarak, işbu Kullanıcı Sözleşmesi’ni
          veya herhangi bir hükmünü ve eklerini, uygun göreceği herhangi bir
          zamanda, Uygulama’da ilan etmek suretiyle değiştirebilir. İşbu
          Kullanıcı Sözleşmesi’nin değişen hükümleri, ilan edildikleri tarihte
          veya önceden belirtilen geçerlilik tarihi olması halinde ise
          geçerlilik tarihinde geçerlilik kazanacak; geri kalan hükümler, aynen
          yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir.
          İşbu Kullanıcı Sözleşmesi, Kullanıcılar’ın tek taraflı beyanları ile
          değiştirilemez. <br />
          <br />
          <h2>MADDE 10 – MÜCBİR SEBEPLER</h2>
          10.1. Mücbir sebep terimi, doğal afet, isyan, savaş, grev, Şirket’in
          gerekli bilgi güvenliği önlemleri almasına karşın sisteme yapılan
          saldırılar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla
          Şirket’in makul kontrolü haricinde gelişen ve Şirket’in gerekli özeni
          göstermesine rağmen önleyemediği kaçınılamayacak olaylar olarak
          yorumlanacaktır. <br />
          <br />
          10.2. Mücbir sebep sayılan tüm durumlarda, Taraflar işbu Sözleşme ile
          belirlenen edinimlerinden herhangi birini geç veya eksik ifa etme veya
          ifa etmeme nedeniyle yükümlü değildir. <br />
          <br />
          <h2>MADDE 11 – KAYITLARIN GEÇERLİLİĞİ</h2> Kullanıcı, işbu Sözleşme’
          den doğabilecek ihtilaflarda Şirket’in kendi veri tabanında,
          sunucularında tuttuğu elektronik ve sistem kayıtlarının, ticari
          kayıtlarının, defter kayıtlarının, mikrofilm, mikrofiş ve bilgisayar
          kayıtlarının muteber bağlayıcı, kesin ve münhasır delil teşkil
          edeceğini ve bu maddenin HMK 193. madde anlamında delil sözleşmesi
          niteliğinde olduğunu kabul, beyan ve taahhüt eder. <br />
          <br />
          <h2>MADDE 12 – TEBLİGAT ADRESLERİ</h2> 12.1. Kullanıcılar’ın, Şirket’
          bildirdiği elektronik posta adresi, bu sözleşme ile ilgili olarak
          yapılacak her türlü bildirim için yasal adresin isteneceği elektronik
          posta olarak kabul edilir. <br />
          <br />
          12.2. Taraflar, mevcut elektronik postalarındaki değişiklikleri yazılı
          olarak diğer tarafa 3 (üç) gün içinde bildirmedikçe, eski elektronik
          postalara yapılacak isteklerin geçerli olacağını ve kendilerine
          yapılmış sayılacağını kabul ederler.
          <br />
          <br />
          <h2>MADDE 13 –YÜRÜRLÜK</h2> Kullanıcı, işbu Sözleşme’de yer alan
          maddelerin tümünü okuduğunu, anladığını, kabul ettiğini ve kendisiyle
          ilgili olarak verdiği bilgilerin doğruluğunu onayladığını beyan, kabul
          ve taahhüt eder. İşbu Sözleşme, Kullanıcı tarafından imzalandığı veya
          elektronik olarak veya sair şekilde onaylandığı tarihte yürürlüğe
          girer.
        </text>
      </div>
      <Footer />
    </div>
  );
};

export default Kullanici;
