import React from "react";

import { GlobalStyle } from "../globalStyles";

import Footer from "../components/Footer";
import Bar from "../components/Bar";

const Gizlilik = () => {
  return (
    <div>
      <GlobalStyle />
      <Bar
        text={"Kişisel Verilerin Korunması ve Gizlilik Politikası"}
        newFont={"clamp(1rem,10vw,2rem)"}
      />
      <div
        style={{
          background: "#0d0909",
          padding: "20px 30px 30px 30px",
          margin: "auto",
          maxWidth: "1300px",
        }}
      >
        <text style={{ color: "white" }}>
          <h2>GİRİŞ</h2> Bu Kişisel Verilerin Korunması ve Gizlilik Politikası,
          Sözleşme’nin ayrılmaz bir parçası olup, burada geçen tanımlı ifadeler
          kendilerine Sözleşme’de verilen anlamı ifade ederler. Bunlara ek
          olarak, aşağıda bulunan terimler, kendilerine aşağıda atfedilen anlamı
          ifade ederler:
          <h2>Açık rıza</h2> Belirli bir konuya ilişkin, bilgilendirmeye dayanan
          ve özgür irade ile açıklanan rızayı ifade eder.
          <h2>Anonim hâle getirme</h2>
          Kişisel verilerin, başka verilerle eşleştirilerek dahi hiçbir surette
          kimliği belirli veya belirlenebilir bir gerçek kişiyle
          ilişkilendirilemeyecek hâle getirilmesini ifade eder.
          <h2>Kişisel veri</h2>
          Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü
          bilgiyi ifade eder. <h2>Kişisel verilerin işlenmesi</h2> Kişisel
          verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri
          kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde
          edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi,
          değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması,
          devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya
          da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen
          her türlü işlemi ifade eder. <h2>Özel nitelikli kişisel veri</h2>
          Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini,
          mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da
          sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkumiyeti ve güvenlik
          tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri ifade
          eder. <h2>Veri işleyen</h2> Veri sorumlusunun verdiği yetkiye
          dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel kişiyi
          ifade eder. <h2>Veri sorumlusu</h2> Kişisel verilerin işleme
          amaçlarını ve vasıtalarını belirleyen, veri kayıt sisteminin
          kurulmasından ve yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi
          ifade eder. Şirket, Sözleşme kapsamındaki faaliyetleri çerçevesinde
          Kullanıcılar’a ilişkin çeşitli amaçlarla kişisel veriler toplayabilir.
          Aşağıda, toplanan kişisel verilerin nasıl ve ne şekilde toplandığı, bu
          verilerin nasıl ve ne şekilde korunduğu belirtilmiştir.
          Kullanıcılar’ın Şirket ile paylaştığı kişisel veriler ve özel
          nitelikli kişisel veriler, 6698 sayılı Kişisel Verilerin Korunması
          Kanunu (“KVKK”) ve ikincil mevzuat kapsamında korunmaktadır. İŞ
          sözleşmeniz kapsamında gerçekleştirilen faaliyetler kapsamında kişisel
          veriler bu Kişisel Verilerin Korunması ve Gizlilik Politikası’nda
          belirtildiği şekilde işlenecektir. Şirket’in Veri Sorumlusu ve
          Temsilcisinin bilgileri aşağıdaki gibidir: <h2>Veri Sorumlusu:</h2>
          BLOCK COFFEE (UMUT ÜSTÜN)
          <h2>Veri Sorumlusu Temsilcisi:</h2>
          BLOCK COFFEE (UMUT ÜSTÜN)
          <h2>KİŞİSEL VERİLERİN TOPLANMASI VE İŞLENME AMAÇLARI</h2> Kullanıcılar
          tarafından kişisel veriler, Sözleşme’nin ifası, İnternet Sitesi
          ve/veya Uygulamanın kullanılması ve sair şekilde Şirket’e
          sunulmaktadır. Şirket söz konusu kişisel veri ve özel nitelikli
          kişisel verileri sözlü, yazılı veya elektronik ortamlarda toplamaya
          yetkilidir. Kişisel veriler aşağıda belirtilen amaçlarla işlenmektedir
          ve üçüncü kişilere aktarılmaktadır: Kullanıcı tarafından çeşitli form
          ve anketlerin doldurulması suretiyle Kullanıcılar’ın kendileriyle
          ilgili bir takım kişisel verileri (isim-soy isim, firma bilgileri,
          telefon, adres veya e-posta adresleri gibi) Şirket tarafından işin
          doğası gereği toplanmaktadır. Şirket bazı dönemlerde Kullanıcılar’a
          kampanya bilgileri, yeni ürünler hakkında bilgiler, promosyon
          teklifleri gönderebilir. Kullanıcılar, bu gibi bilgileri alıp almama
          konusunda her türlü seçimi Sözleşme’yi onaylarken veya sonrasında
          İnternet Sitesi ve/veya Uygulama üzerinden değiştirilebilir ya da
          kendisine gelen bilgilendirme iletisindeki linkle bildirim yapabilir
          İnternet Sitesi ve/veya Uygulama üzerinden veya eposta ile
          gerçekleştirilen onay sürecinde, Kullanıcılar tarafından Şirket’e
          elektronik ortamdan iletilen kişisel bilgiler, Sözleşme’de belirlenen
          amaçlar ve kapsam dışında üçüncü kişilere açıklanmayacaktır. Bu
          Politika kapsamında belirtilen istisnalar saklıdır. Şirket’in sunduğu
          ürün ve hizmetlerle, Uygulama’yla ve sistemle ilgili sorunların
          tanımlanması ve verilen hizmet ile ilgili çıkabilecek sorunların veya
          uyuşmazlıkların hızla çözülmesi için, Şirket, Kullanıcılar’ın IP
          adresini kaydetmekte ve bunu kullanmaktadır. IP adresleri,
          Kullanıcılar’ı genel bir şekilde tanımlamak ve kapsamlı demografik
          bilgi toplamak amacıyla da kullanılabilir. Şirket, Sözleşme’nin amaç
          ve kapsamı dışında da talep edilen bilgileri kendisi veya işbirliği
          içinde olduğu kişiler tarafından doğrudan pazarlama yapmak amacıyla
          kullanabilir. Kişisel veriler, gerektiğinde Kullanıcı’yla temas kurmak
          için de kullanılabilir. Şirket tarafından talep edilen bilgiler veya
          Kullanıcı tarafından sağlanan bilgiler veya Şirket üzerinden yapılan
          işlemlerle ilgili bilgiler; Şirket ve işbirliği içinde olduğu kişiler
          tarafından, Sözleşme ile belirlenen amaçlar ve kapsam dışında da,
          Kullanıcılar’ın kimliği ifşa edilmeden çeşitli istatistiksel
          değerlendirmeler, veri tabanı oluşturma ve pazar araştırmalarında
          kullanılabilir. Şirket üçüncü kişilere veri aktarımı sırasında hak
          ihlallerini önlemek için gerekli teknik ve hukuki önlemler almaktadır.
          Bununla birlikte, kişisel verileri alan üçüncü kişinin veri koruma
          politikalarından dolayı Şirket sorumlu değildir.
          <h2>KULLANICI’NIN KİŞİSEL VERİLERE İLİŞKİN HAKLARI</h2> KVKK m. 11
          uyarınca Kullanıcı, kişisel veri sahibi olarak Şirket’e aşağıda yer
          alan konularda başvurabilir: Kişisel verilerin işlenip işlenmediğini
          öğrenme, Kişisel veriler işlendiyse buna ilişkin bilgi talep etme,
          İşlenen kişisel verilerin işlenme amacı ve bunların amacına uygun
          kullanılıp kullanılmadığını öğrenme, Yurt içinde ve yurt dışında
          kişisel verilerin aktarıldığı üçüncü kişileri öğrenme, Kişisel
          verilerin eksik veya yanlış işlenmiş olması halinde bunların
          düzeltilmesini isteme ve bu talebiniz uyarınca yapılan işlemlerin
          kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,
          KVKK m. 7’de öngörülen şartlar çerçevesinde, kişisel verinin
          işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel
          verilerin silinmesini veya yok edilmesini isteme ve bu talep uyarınca
          yapılan işlemlerin kişisel verilerin aktarıldığı üçüncü kişilere
          bildirilmesini isteme, İşlenen verilerin münhasıran otomatik sistemler
          vasıtasıyla analiz edilmesi suretiyle Kullanıcı aleyhine bir sonucun
          ortaya çıkmasına itiraz etme, Kişisel verilerinin kanuna aykırı olarak
          işlenmesi sebebiyle zarara uğrama halinde zararın giderilmesini talep
          etme. KVKK m. 13 uyarınca Kullanıcılar, yukarıdaki haklarına ilişkin
          Şirket’e yapacağı talepleri, Şirket’e yazılı olarak iletebilir.{" "}
          <h2>KREDİ KARTI GÜVENLİĞİ</h2> Şirket, Kullanıcılar’ın kredi kartı
          bilgilerini hiçbir şekilde saklamamaktadır. İşlemler sürecine
          Kullanıcılar’ın güvenli bir sitede olduğunuzu anlamak için dikkat
          etmesi gereken en önemli husus tarayıcının en alt satırında bulunan
          bir anahtar ya da kilit simgesidir. Bu Kullanıcı’nın güvenli bir
          internet sayfasında olduğunu gösterir. Kredi kartı bilgileri, ancak
          satış işlemleri sürecine bağlı olarak ve Kullanıcı’nın talimatı
          istikametinde kullanılır. Alışveriş sırasında kullanılan kredi kartı
          ile ilgili bilgiler alışveriş sitelerinden bağımsız olarak 128 bit SSL
          (Secure Sockets Layer) protokolü ile şifrelenip sorgulanmak üzere
          ilgili bankaya ulaştırılır. Kartın kullanılabilirliği onaylandığı
          takdirde alışverişe devam edilir. Kartla ilgili hiçbir bilgi Şirket
          tarafından görüntülenemediğinden ve kaydedilmediğinden, üçüncü
          şahısların herhangi bir koşulda bu bilgileri ele geçirmesi engellenmiş
          olur. Kullanıcı, verdiği tüm bilgilere bizzat ulaşabilir ve
          değiştirebilir. Üye giriş bilgilerinizi güvenli koruduğu takdirde
          başkalarının Kullanıcı ile ilgili bilgilere ulaşması ve bunları
          değiştirmesi mümkün değildir. Bu amaçla, üyelik işlemleri sırasında
          128 bit SSL güvenlik alanı içinde hareket edilir. Bu sistem kırılması
          mümkün olmayan bir uluslararası bir şifreleme standardıdır.
          <h2>ÜÇÜNCÜ TARAF WEB SİTELERİ VE UYGULAMALAR</h2> Şirket, İnternet
          Sitesi ve Uygulama dâhilinde başka sitelere link verebilir. Şirket, bu
          linkler vasıtasıyla erişilen sitelerin gizlilik uygulamaları ve
          içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır. İnternet
          Sitesi veya Uygulama’da yayınlanan reklamlar, reklamcılık yapan iş
          ortaklarımız aracılığı ile Kullanıcılar’a dağıtılır. İş bu
          sözleşmedeki Prensipler, sadece İnternet Sitesi ve Uygulama’nın
          kullanımına ilişkindir, üçüncü taraf web sitelerini, uygulamalarını
          veya hizmetlerini kapsamaz. <h2>DİĞER İSTİSNAİ HALLER</h2> Yukarıda
          belirtilen hallere ek olarak, aşağıda belirtilen sınırlı hallerde
          Şirket, işbu Kişisel Verilerin Korunması ve Gizlilik Politikası
          hükümleri dışında kullanıcılara ait bilgileri üçüncü kişilere
          açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere; Kanun, kanun
          hükmünde kararname, yönetmelik v.b. yürürlülükte olan mevzuatın
          getirdiği zorunluluklara uymak; Sözleşme kapsamındaki hakların
          kullanılması ve yükümlülüklerin ifa edilmesi amacıyla; Yetkili idari
          ve adli otorite ile usulüne göre yürütülen bir araştırma veya
          soruşturma kapsamında veya derdest bir dava kapsamında mahkemenin
          kararı ile bilgi talep edilmesi; Kullanıcıların hakları veya
          güvenliklerini korumak için bilgi vermenin gerekli olduğu hallerdir.
          <h2>E-POSTA GÜVENLİĞİ</h2> Kullanıcılar, Şirket’e veya Şirket’in
          Müşteri Hizmetleri’ne, herhangi bir husus ilgili olarak göndereceği
          bildirilerde kredi kartı numarasına ve şifrelerine yer vermemelidir.
          E-posta ve sair yolla iletilen bilgiler üçüncü şahıslar tarafından
          görülebilir ve Şirket bu yollarla iletilen bilgilerin güvenliğini
          hiçbir koşulda garanti edemez. <h2>TARAYICI ÇEREZLERİ</h2> Şirket,
          İnternet Sitesi’ni ziyaret eden veya Uygulama’yı kullanan Kullanıcılar
          ve Kullanıcılar’ın web sitesini kullanımı hakkındaki bilgileri teknik
          bir iletişim dosyası (Çerez-Cookie) kullanarak elde edebilir. Bahsi
          geçen teknik iletişim dosyaları, ana bellekte saklanmak üzere bir
          internet sitesinin kullanıcının tarayıcısına (browser) gönderdiği
          küçük metin dosyalarıdır. Teknik iletişim dosyası site hakkında durum
          ve tercihleri saklayarak İnternet’in kullanımını kolaylaştırır. Teknik
          iletişim dosyası, siteyi kaç kişinin ziyaret ettiğini, bir kişinin
          siteyi hangi amaçla, kaç kez ziyaret ettiğini ve ne kadar sitede
          kaldıkları hakkında istatistiksel bilgileri elde etmeye ve
          kullanıcılar için özel tasarlanmış kullanıcı sayfalarından dinamik
          olarak reklam ve içerik üretilmesine yardımcı olur. Teknik iletişim
          dosyası, ana bellekte veya e-postanızdan veri veya başkaca herhangi
          bir kişisel bilgi almak için tasarlanmamıştır. Tarayıcıların pek çoğu
          başta teknik iletişim dosyasını kabul eder biçimde tasarlanmıştır
          ancak Kullanıcılar dilerse teknik iletişim dosyasının gelmemesi veya
          teknik iletişim dosyasının gönderildiğinde uyarı verilmesini
          sağlayacak biçimde ayarları değiştirebilirler. Şirket, işbu Kişisel
          Verilerin Korunması ve Gizlilik Politikası hükümlerini dilediği zaman
          sitede yayınlamak veya Kullanıcılar’a elektronik posta veya Mesaj
          göndermek veya İnternet Sitesi veya Uygulama’da yayınlamak suretiyle
          değiştirebilir. Kişisel Verilerin Korunması ve Gizlilik Politikası
          hükümleri değiştiği takdirde, yayınlandığı tarihte yürürlük kazanır.
          Kişisel Verilerin Korunması ve Gizlilik Politikası ile ilgili
          Kullanıcılar, her türlü soru ve önerileri için e-posta gönderebilir ve
          Şirket’e aşağıdaki iletişim bilgilerinden ulaşabilirler. Şirket
          Unvanı: BLOCK COFFEE (UMUT ÜSTÜN) Adres: Değirmenaltı Mah. İğde Sok.
          No:6/A Süleymanpaşa/Tekirdağ E-posta: info@blockcoffee.com.tr
        </text>
      </div>
      <Footer />
    </div>
  );
};

export default Gizlilik;
