import React from "react";

import { GlobalStyle } from "../globalStyles";

import Footer from "../components/Footer";
import Bar from "../components/Bar";

const ContactUs = () => {
  return (
    <div>
      <GlobalStyle />
      <Bar
        text={"İLETİŞİM"}
        text3={"Bize Ulaşın"}
        location={
          "https://forms.gle/8FBFFKmnPi4euXRM8"
        }
      />

      <Footer />
    </div>
  );
};

export default ContactUs;
