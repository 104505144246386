import React from "react";
import { FeatureContainer, FeatureButton } from "./FeatureElements";

const Feature = () => {
  return (
    <FeatureContainer>
      <h1>FRANCHISE</h1>
      <p>
        <span style={{ fontFamily: "Montserrat-ExtraBold" }}>Block.</span> Coffee
        ailesinin bir parçası olmak istiyorsan aşağıdaki bağlantıdan formu
        doldurabilirsin.
      </p>
      <FeatureButton
        target="_blank"
        onClick={() => {
          window.location =
            "https://forms.gle/YKJ68zNuDkrogV3K6";
        }}
      >
        Başvuru Yap
      </FeatureButton>
    </FeatureContainer>
  );
};

export default Feature;
