import React from "react";

import { GlobalStyle } from "../globalStyles";

import Footer from "../components/Footer";
import Bar from "../components/Bar";

const Franchise = () => {
  return (
    <div>
      <GlobalStyle />
      <Bar
        text={"FRANCHISE"}
        text2={"Block. coffee ailesinin bir parçası olun!"}
        text3={"Başvuru Yap"}
        location={
          "https://forms.gle/YKJ68zNuDkrogV3K6"
        }
      />

      <Footer />
    </div>
  );
};

export default Franchise;
