import React from "react";
import { FeatureContainer, FeatureButton } from "./FeatureElements";
import ImgBg from "../../images/block_folyo_slogan_01.png";

const Feature = () => {
  return (
    <FeatureContainer>
      <img
        style={{ height: "95%", display: "flex", placeSelf: "flex-start" }}
        src={ImgBg}
        alt="block"
      />
    </FeatureContainer>
  );
};

export default Feature;
