import React from "react";
import {
  ProductsContainer,
  ProductWrapper,
  ProductsHeading,
  ProductTitle,
  ProductCard,
  ProductImg,
  ProductInfo,
  ProductDesc,
  ProductPrice,
  ProductButton,
} from "./ProductsElements";

const Products = ({ heading, data }) => {
  return (
    <ProductsContainer>
      <ProductsHeading>{heading}</ProductsHeading>
      <span style={{ display: "block", marginBottom: "20px", padding: "0 20px" }}>
        Block Coffee 2023 yılında, minimalist bir dekorasyon anlayışıyla
        Tekirdağ'da kuruldu. Yılların getirmiş olduğu engin tecrübe ve bilgi
        birikimini, kahve çekirdekleriyle harmanlayarak, eşsiz tadını kahve
        severlerle buluşturdu. Kahvelerini kavururken hasat dönemini, dünya
        trendlerini ve misafir geri bildirimlerini göz önünde bulundururken en
        taze, en doğal ve nitelikli kahve çekirdeklerini kullanmaya özen
        göstermektedir.
      </span>
      <ProductWrapper>
        {data.map((product, index) => {
          return (
            <ProductCard key={index}>
              <ProductImg src={product.img} alt={product.alt} />
              <ProductInfo>
                <ProductTitle>{product.name}</ProductTitle>
              </ProductInfo>
            </ProductCard>
          );
        })}
      </ProductWrapper>
    </ProductsContainer>
  );
};

export default Products;
