import React from "react";

import { GlobalStyle } from "../globalStyles";

import Footer from "../components/Footer";
import Products from "../components/Products";
import { productsData } from "../components/Products/data";
import Bar from "../components/Bar";

const Hakkimizda = () => {
  return (
    <div>
      <GlobalStyle />
      <Bar text={"Hakkımızda"} />
      <Products heading="Hakkımızda" data={productsData} />

      <Footer />
    </div>
  );
};

export default Hakkimizda;
