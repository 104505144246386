import React from "react";

import { GlobalStyle } from "../globalStyles";
import Hero from "../components/Hero";
import Products from "../components/Products";
import { productsData } from "../components/Products/data";
import Feature from "../components/Feature";
import Footer from "../components/Footer";
import Back from "../components/Back";

const Home = () => {
  return (
    <div>
      <GlobalStyle />
      <Hero />
      <Back />
      <Products heading="Hakkımızda" data={productsData} />

      <Feature />
      <Footer />
    </div>
  );
};

export default Home;
