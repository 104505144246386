import React, { useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroBtn,
} from "./HeroElements";

const Hero = ({ text, text2, text3, location, newFont }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <HeroContainer>
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <HeroContent>
        <HeroItems>
          {newFont ? (
            <HeroH1 style={{ fontSize: newFont }}>{text}</HeroH1>
          ) : (
            <HeroH1>{text}</HeroH1>
          )}
          <HeroP>{text2}</HeroP>
          {text3 && (
            <HeroBtn
              onClick={() => {
                window.location = location;
              }}
            >
              {text3}
            </HeroBtn>
          )}
        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
