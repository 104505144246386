import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Franchise from "./pages/Franchise";
import Kullanici from "./pages/Kullanici";
import Gizlilik from "./pages/Gizlilik";
import "./App.css";
import Hakkimizda from "./pages/Hakkimizda";
import ContactUs from "./pages/ContanctUs";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/menu" Component={Home} />
        <Route path="/iletisim" Component={ContactUs} />
        <Route path="/subeler" Component={Home} />
        <Route path="/hakkimizda" Component={Hakkimizda} />
        <Route path="/franchise" Component={Franchise} />
        <Route path="/kullanici-sozlesmesi" Component={Kullanici} />
        <Route path="/gizlilik-politikasi" Component={Gizlilik} />

        <Route path="*" Component={Home} />
      </Routes>
    </Router>
  );
}

export default App;
